<template>
  <el-dialog class="top-dialog" :title="title" :close-on-click-modal="true" :visible="visible" @close="handleColseDialog">
    <div class="mb-2" v-if="tips">{{ tips }}</div>
    <el-input type="textarea" show-word-limit maxlength="100" placeholder="请输入(限制100个字符)" v-model="textarea" :autosize="{ minRows: 4, maxRows: 6 }" :maxlength="100"> </el-input>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleColseDialog">{{ cancelText }}</el-button>
      <el-button type="primary" @click="handelSubmit" :loading="loading">{{ loading ? '提交中...' : submitText }}</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    tips: {
      type: String,
      default: ''
    },
    submitText: {
      type: String,
      default: '确 定'
    },
    cancelText: {
      type: String,
      default: '取 消'
    },
    beforeClose: {
      type: Function
    }
  },
  data() {
    return {
      textarea: ''
    }
  },
  methods: {
    handleColseDialog() {
      this.$emit('update:visible', false)
      this.$emit('close')
      this.textarea = ''
    },
    async handelSubmit() {
      if (!this.textarea) {
        return this.$message.warning(this.tips || `请输入${this.title}`)
      }
      this.$emit('submit', this.textarea)
      if (this.beforeClose) {
        await this.beforeClose()
      }
      this.handleColseDialog()
    }
  }
}
</script>
