<template>
  <div class="page-wrapper white">
    <div class="page-info psychology-page">
      <template v-if="[4, 5, 6, 7, 8].includes(info.psychology_status) || (info.create_type === 2 && info.psychology_status === 1)">
        <div class="status-img"><img :src="`${$OssAddress}s_${[8].includes(info.psychology_status) ? 6 : info.psychology_status}.png`" alt="咨询状态" /></div>
      </template>
      <Card :title="title">
        <el-row class="page-row w180">
          <el-col class="item">
            <div class="inner">
              <div class="label">咨询学生姓名</div>
              <div class="content">{{ info.student_name }}</div>
            </div>
          </el-col>
          <el-col class="item">
            <div class="inner">
              <div class="label">性别</div>
              <div class="content">{{ info.gender }}</div>
            </div>
          </el-col>
          <el-col class="item">
            <div class="inner">
              <div class="label">学号</div>
              <div class="content">{{ info.student_no }}</div>
            </div>
          </el-col>
          <el-col class="item">
            <div class="inner">
              <div class="label">行政班</div>
              <div class="content">{{ info.class_name }}</div>
            </div>
          </el-col>
          <el-col class="item">
            <div class="inner">
              <div class="label">年级</div>
              <div class="content">{{ info.grade_name }}</div>
            </div>
          </el-col>
          <el-col class="item">
            <div class="inner">
              <div class="label">咨询主题</div>
              <div class="content">
                {{ info.theme_name }}
              </div>
            </div>
          </el-col>
          <el-col class="item">
            <div class="inner">
              <div class="label">咨询次数</div>
              <div class="content">{{ info.counseling_count }}</div>
            </div>
          </el-col>
          <template v-if="info.psychology_status === 1 && info.create_type === 1">
            <el-col class="item">
              <div class="inner">
                <div class="label">咨询时间</div>
                <div class="content">
                  <el-button type="primary" plain size="small" @click="handleShowDialog">
                    {{ date ? `${date} ${start_at} ~ ${date} ${end_at}` : '选择时间' }}
                  </el-button>
                </div>
              </div>
            </el-col>
            <el-col class="item">
              <div class="inner">
                <div class="label">咨询地点</div>
                <div class="content">
                  <el-select v-model="chamber_id" placeholder="请选择">
                    <el-option v-for="item in roomList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                  </el-select>
                </div>
              </div>
            </el-col>
          </template>
          <template v-else>
            <template v-if="info.psychology_status !== 5">
              <el-col class="item">
                <div class="inner">
                  <div class="label">咨询时间</div>
                  <div class="content">
                    {{ this.showDate || info.time }}
                    <template v-if="(info.create_type === 2 && info.psychology_status === 1) || info.psychology_status === 2">
                      <el-button style="margin-left: 10rem;" type="primary" plain size="small" @click="handleShowDialog">修改时间</el-button>
                      <el-button debounce type="primary" size="small" @click="handleChangeDate" v-if="showDate">保存修改</el-button>
                    </template>
                  </div>
                </div>
              </el-col>
              <el-col class="item">
                <div class="inner">
                  <div class="label">咨询地点</div>
                  <div class="content">{{ info.chamber_name }}</div>
                </div>
              </el-col>
            </template>
          </template>
          <template v-if="info.create_type === 1">
            <el-col class="item">
              <div class="inner">
                <div class="label">学生留言</div>
              </div>
            </el-col>
            <el-col class="item">
              <div class="inner">
                <div class="content">{{ info.student_remark }}</div>
              </div>
            </el-col>
            <template v-if="info.teacher_remark">
              <el-col class="item">
                <div class="inner">
                  <div class="label">我的留言</div>
                </div>
              </el-col>
              <el-col class="item">
                <div class="inner">
                  <div class="content">{{ info.teacher_remark }}</div>
                </div>
              </el-col>
            </template>
          </template>
          <template v-else-if="info.create_type === 2">
            <el-col class="item">
              <div class="inner">
                <div class="label">我的留言</div>
                <div class="content">{{ info.teacher_remark }}</div>
              </div>
            </el-col>
            <template v-if="info.student_remark">
              <el-col class="item">
                <div class="inner">
                  <div class="label">学生留言</div>
                </div>
              </el-col>
              <el-col class="item">
                <div class="inner">
                  <div class="content">{{ info.student_remark }}</div>
                </div>
              </el-col>
            </template>
          </template>

          <template v-if="info.psychology_status === 4">
            <el-col class="item">
              <div class="inner">
                <div class="label">实际开始咨询时间</div>
                <div class="content">{{ info.start_time }}</div>
              </div>
            </el-col>
            <el-col class="item">
              <div class="inner">
                <div class="label">实际结束咨询时间</div>
                <div class="content">{{ info.end_time }}</div>
              </div>
            </el-col>
          </template>
          <template v-else-if="info.psychology_status === 5">
            <el-col class="item">
              <div class="inner">
                <div class="label">拒绝原因</div>
              </div>
            </el-col>
            <el-col class="item">
              <div class="inner">
                <div class="content">{{ info.refuse_remark }}</div>
              </div>
            </el-col>
          </template>
          <template v-else-if="info.psychology_status === 6">
            <el-col class="item">
              <div class="inner">
                <div class="label">取消原因</div>
              </div>
            </el-col>
            <el-col class="item">
              <div class="inner">
                <div class="content">{{ info.refuse_remark }}</div>
              </div>
            </el-col>
          </template>
        </el-row>
        <TimeSelect ref="timeSelectDom" :visible.sync="isShowDialog" :school_id="school_id" @submit="handleDateSubmit"></TimeSelect>
        <Action
          :visible.sync="isShowDialogTexteare"
          :title="getinfo().title"
          :tips="getinfo().tips"
          :loading="loading"
          :beforeClose="handleBeforeClose"
          @close="handleActionClose"
          @submit="handleActionSubmit"
        ></Action>
      </Card>
    </div>
    <div class="page-tool border">
      <el-button :type="[4, 5, 6, 7].includes(info.psychology_status) ? 'primary' : ''" @click="$router.back()">返回</el-button>
      <template v-if="info.psychology_status === 1">
        <template v-if="info.create_type === 1">
          <el-button debounce type="primary" @click="handleSelect(0)">接受预约</el-button>
          <el-button type="info" debounce @click="handleSelect(1)">拒绝预约</el-button>
        </template>
        <template v-else-if="info.create_type === 2">
          <el-button type="primary" debounce @click="handleSelect(3)">取消预约</el-button>
        </template>
      </template>
      <template v-else-if="info.psychology_status === 2">
        <el-button debounce type="primary" @click="handleStart">开始咨询</el-button>
        <el-button type="info" @click="handleSelect(2)">取消咨询</el-button>
      </template>
      <el-button debounce type="primary" @click="handleAccomplish" v-else-if="info.psychology_status === 3">完成咨询</el-button>
    </div>
  </div>
</template>

<script>
import TimeSelect from '../components/TimeSelect.vue'
import Action from '../components/Action.vue'
import Card from '@/components/common/Card.vue'
import { formatDate } from '@/utils'
import { getApi2, putApi2 } from '@/api'
export default {
	_config:{"route":{"path":"detail","meta":{"title":"预约申请详情"}}},
  name: 'ApplyDetail',
  components: { TimeSelect, Action, Card },
  data() {
    return {
      info: {},
      roomList: [],
      chamber_id: '',
      showDate: '',
      date: '',
      start_at: '',
      end_at: '',
      remark: '',
      type: 0,
      isShowDialog: false,
      isShowDialogTexteare: false,
      loading: false,
      school_id: 0
    }
  },
  watch: {
    school_id(val) {
      if (val) {
        this.getRoom()
      }
    }
  },
  created() {
    this.getDetail()
    this.title = `预约${this.$route.query.type == 1 ? '申请' : '邀请'}详情`
  },
  methods: {
    getinfo() {
      let tips = '',
        title = '留给学生的话',
        type = ''
      if (this.type) {
        type = '取消'
        title = '咨询'
        if (this.info.psychology_status === 1) {
          title = '预约'
          if (this.info.create_type === 1) {
            type = '拒绝'
          }
        }
        title = `${type}${title}`
        tips = `请输入${type}原因`
      }
      return { title, tips, type }
    },
    getDetail() {
      getApi2('/evaluation/appointment/counselling/detail', { id: this.$route.query.id }).then(res => {
        this.info = res
        this.school_id = res.school_id
        if(this.showDate) {
          this.showDate = ''
        }
      })
    },
    getRoom() {
      getApi2('/evaluation/counseling-setting/chamber-enumeration', { school_id: this.school_id }).then(res => {
        this.roomList = res.map(item => ({ label: `${item.chamber_name}（${item.building_name}${item.chamber_no}）`, value: item.id }))
      })
    },
    handleShowDialog() {
      this.isShowDialog = true
    },
    handleChangeDate() {
      const data = { id: this.$route.query.id, date: this.date, start_at: this.start_at, end_at: this.end_at }
      this.loading = true
      putApi2('/evaluation/appointment/change-time', data).then(() => {
        this.$message.success('操作成功！')
        this.date = ''
        this.start_at = ''
        this.end_at = ''
        this.getDetail()
        this.$refs.timeSelectDom.getList()
      })
      .finally(() => {
        this.loading = false
      })
    },
    handleSelect(type) {
      this.type = type
      if (!type && (!this.chamber_id || !this.start_at || !this.end_at)) {
        this.$message.warning(!this.start_at || !this.end_at ? '请选择咨询时间' : '请选择咨询地点')
        return
      }
      this.isShowDialogTexteare = true
    },
    handleDateSubmit(val) {
      const { date, start_at, end_at } = val
      this.date = date
      this.start_at = start_at
      this.end_at = end_at
      if((this.info.create_type === 2 && this.info.psychology_status === 1) || this.info.psychology_status === 2) {
        const { date, week } = formatDate(val.date, { separator: '/' })
        const arr = ['周日', '周一', '周二', '周三', '周四', '周五', '周六']
        this.showDate = `${date} ${start_at}-${end_at}（${arr[week]}）`
      }
    },
    handleBeforeClose() {
      return new Promise(resolve => {
        let data = {
          id: this.info.id
        }
        let api = ''
        if (this.type === 1) {
          data.reason = this.remark
          api = '/evaluation/appointment/refuse'
        } else if (this.type === 2) {
          api = '/evaluation/appointment/cancel'
          data.reason = this.remark
        } else if (this.type === 3) {
          api = '/evaluation/appointment/abandon'
          data.reason = this.remark
        } else if (this.type === 0) {
          api = '/evaluation/appointment/accept'
          data.date = this.date
          data.chamber_id = this.chamber_id
          data.start_at = this.start_at
          data.end_at = this.end_at
          data.remark = this.remark
        }
        this.loading = true
        putApi2(api, data)
          .then(() => {
            resolve(1)
            this.$message.success('操作成功！')
            this.$router.back()
          })
          .finally(() => {
            this.loading = false
          })
      })
    },
    handleActionClose() {
      this.remark = ''
    },
    handleActionSubmit(val) {
      this.remark = val
    },
    handleAccomplish() {
      let that = this
      this.$confirm('本次咨询已经结束，请填写咨询后记录，不填写记录内容，会视为未完成咨询哦！', '提示', {
        distinguishCancelAndClose: true,
        confirmButtonText: '马上记录',
        cancelButtonText: '一会儿再去',
        type: 'warning'
      })
        .then(() => {
          that.loading = true
          putApi2('/evaluation/appointment/end', { id: that.info.id })
            .then(res => {
              that.$router.push(`/edu/psychology/records/summary?id=${res.id}&back=list`)
            })
            .finally(() => {
              that.loading = false
            })
        })
        .catch(action => {
          if (action === 'cancel') {
            that.loading = true
            putApi2('/evaluation/appointment/end', { id: that.info.id })
              .then(res => {
                that.$router.back()
              })
              .finally(() => {
                that.loading = false
              })
          }
        })
    },
    handleStart() {
      let that = this
      this.$confirm('确认后开始本次咨询！', '提示', {
        confirmButtonText: '马上开始',
        cancelButtonText: '再等会儿',
        type: 'warning'
      })
        .then(() => {
          that.loading = true
          putApi2('/evaluation/appointment/begin', { id: that.info.id })
            .then(res => {
              that.$message.success(`操作成功！`)
              that.getDetail()
            })
            .finally(() => {
              that.loading = false
            })
        })
        .catch(action => {})
    }
  }
}
</script>

<style lang="scss" scoped></style>
